import * as React from 'react';
import Head from 'next/head';
import Layout, { ChildrenWrapper, JustifyContentType } from '@kvika/layout';
import Header from '@kvika/header';
import LayoutImage from '../components/layout/LayoutImage';

const Page404 = () => {
  return (
    <>
      <Head>
        <title>Kvika</title>
      </Head>
      <Layout image={<LayoutImage url="/mynd_vindmyllur.png" alt="Vindmyllur og landslag" />}>
        <Header />
        <ChildrenWrapper justifyContent={JustifyContentType.Center}>
          <h1>404</h1>
          <h3>Síða fannst ekki</h3>
        </ChildrenWrapper>
      </Layout>
    </>
  );
};
export default Page404;
